'use client';

import { Button } from '@av/ui/button';
import { AccessoryVariant, Accessory, Product } from '@av/database';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@av/ui/dialog';
import { ImgNotAvailable } from '@/assets/img-not-available';
import { cn, formatter } from '@av/utils';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@av/ui/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useEffect, useTransition } from 'react';
import { AccessoriesForm, AccessoriesFormSchema } from '@av/validators';
import Counter from '@/components/form/counter/counter';
import { notifyError } from '@av/bugsnag';
import { toast } from 'react-hot-toast';
import { PlusCircle, Trash2 } from 'lucide-react';
import { useTaxState } from '../context/tax-state-context';

export type AccessoryItem = Accessory & {
  variants: (AccessoryVariant & { product: Product })[];
  product: Product;
};

export type ProductState = { productId: number; quantity: number };

export type AccessoryState = ProductState & {
  product: Product;
  quantity: number;
  accessories: AccessoryItem[];
};

interface AccessoriesModalProps {
  isOpen: boolean;
  onClose: () => void;
  addToCart: (a: ProductState[]) => void;
  state: AccessoryState | null;
}

export const AccessoriesModal: React.FC<AccessoriesModalProps> = ({
  isOpen,
  onClose,
  state,
  addToCart,
}) => {
  const [loading, startTransition] = useTransition();
  const { isTaxIncluded } = useTaxState();

  const form = useForm<AccessoriesForm>({
    resolver: zodResolver(AccessoriesFormSchema),
  });

  useEffect(() => {
    if (state)
      form.reset({
        accessories: state.accessories.map((a) => {
          const defaultVariant = a.variants.find((v) => v.isDefault) || a.variants[0];
          const defaultQuantity = (defaultVariant?.defaultQuantity || 1) * state.quantity;
          const minQuantity = defaultVariant?.product.minQuantity || 1;

          return {
            products: [
              {
                productId: defaultVariant?.productId,
                quantity: Math.max(minQuantity, defaultQuantity),
              },
            ],
          };
        }),
      });
  }, [state]);

  const onSubmit = async (data: AccessoriesForm) => {
    startTransition(async () => {
      try {
        await addToCart([state!, ...data.accessories.flatMap((a) => a.products)]);
      } catch (e: unknown) {
        notifyError(e, 'Fout bij het indienen van producten met accessoires');
        toast.error('Toevoegen mislukt. Probeer het opnieuw of neem contact op.');
      }
    });
  };
  const onChange = (open: boolean) => {
    if (!open) {
      onClose();
    }
  };

  const title = state?.product.title || 'artikel';

  return (
    <Dialog open={isOpen} onOpenChange={onChange}>
      <DialogContent className="max-sm:max-w-screen overflow-auto bg-white p-4 max-sm:max-h-screen sm:max-w-[62.5rem] sm:p-8">
        <DialogHeader className="max-sm:max-w-[calc(100vw-2rem)]">
          <DialogTitle className="mb-2 text-xl sm:text-3xl">
            Wilt u de benodigde accessoires direct meebestellen?
          </DialogTitle>
          <DialogDescription className="text-muted-fg">
            Om uw ervaring compleet te maken, raden we aan enkele essentiële accessoires direct bij
            uw {title} te bestellen. We hebben een selectie van accessoires en de bijbehorende
            aantallen alvast voor u uitgezocht.
          </DialogDescription>
        </DialogHeader>
        <div className="max-sm:max-w-[calc(100vw-2rem)]">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="w-full">
              <FormField
                control={form.control}
                name="accessories"
                render={({ field: { value: accessories = [], onChange } }) => (
                  <FormItem>
                    <FormControl>
                      <div>
                        {accessories.map(({ products }, i) => {
                          const stateAccessory = state?.accessories[i];
                          const variants = stateAccessory?.variants || [];

                          return (
                            <FormField
                              key={i}
                              control={form.control}
                              name={`accessories.${i}.products`}
                              render={() => (
                                <FormItem>
                                  <FormControl>
                                    <div>
                                      {products.map((_, productIdx) => {
                                        return (
                                          <FormField
                                            key={productIdx}
                                            control={form.control}
                                            name={`accessories.${i}.products.${productIdx}`}
                                            render={({ field: productField }) => {
                                              const product = variants.find(
                                                (v) => v.productId === productField.value.productId
                                              )?.product;
                                              if (!product) return <></>;

                                              const taxFactor = product.taxRate
                                                ? product.taxRate / 100
                                                : 0;
                                              const priceFactor = isTaxIncluded ? 1 + taxFactor : 1;
                                              const productPrice =
                                                ((product.unitPrice || 0) * priceFactor) / 100;

                                              return (
                                                <FormItem>
                                                  <FormControl>
                                                    <div
                                                      className={cn(
                                                        'flex sm:space-x-3 max-sm:flex-col w-full sm:items-center py-5',
                                                        productIdx + 1 < products.length
                                                          ? 'border-b border-gray-200'
                                                          : ''
                                                      )}
                                                    >
                                                      <div className="relative aspect-square h-[60px] overflow-hidden rounded bg-white md:h-[100px]">
                                                        {product.thumbnailImage ? (
                                                          <img
                                                            src={product.thumbnailImage}
                                                            className="size-[60px] object-contain md:size-[100px]"
                                                            alt={product.title}
                                                          />
                                                        ) : (
                                                          <ImgNotAvailable className="size-full p-2" />
                                                        )}
                                                      </div>
                                                      <div className="w-full space-y-2 max-sm:-mx-1 max-sm:overflow-hidden max-sm:p-1">
                                                        <div className="flex justify-between">
                                                          <div>
                                                            <h3 className="text-base font-semibold sm:text-lg">
                                                              {stateAccessory?.title}
                                                            </h3>
                                                            <p className="text-xs text-muted-fg sm:text-sm">
                                                              Prijs per stuk:{' '}
                                                              {formatter.format(productPrice)}
                                                            </p>
                                                          </div>
                                                          <div className="text-base font-semibold sm:text-lg">
                                                            {formatter.format(
                                                              productPrice *
                                                                productField.value.quantity
                                                            )}
                                                          </div>
                                                        </div>
                                                        <div className="flex gap-2">
                                                          {variants.length > 1 && (
                                                            <Select
                                                              onValueChange={(v) => {
                                                                productField.onChange({
                                                                  ...productField.value,
                                                                  productId: +v,
                                                                  quantity:
                                                                    variants.find(
                                                                      (p) => p.productId === +v
                                                                    )?.defaultQuantity ||
                                                                    productField.value.quantity ||
                                                                    1,
                                                                });
                                                              }}
                                                              value={
                                                                productField.value
                                                                  .productId as unknown as string
                                                              }
                                                            >
                                                              <SelectTrigger className="h-10 w-full overflow-hidden whitespace-nowrap pr-10 max-sm:pl-2 max-sm:pr-5 sm:w-fit">
                                                                <SelectValue placeholder="Kies een variant" />
                                                              </SelectTrigger>
                                                              <SelectContent className="z-[999999999999999]">
                                                                {variants.map((v) => (
                                                                  <SelectItem
                                                                    key={v.id}
                                                                    value={
                                                                      v.productId as unknown as string
                                                                    }
                                                                  >
                                                                    {v.title}
                                                                  </SelectItem>
                                                                ))}
                                                              </SelectContent>
                                                            </Select>
                                                          )}

                                                          <Counter
                                                            className="w-min"
                                                            disabled={loading}
                                                            value={productField.value.quantity}
                                                            onChange={(quantity) =>
                                                              productField.onChange({
                                                                ...productField.value,
                                                                quantity,
                                                              })
                                                            }
                                                            step={product.step || 1}
                                                            min={product.minQuantity || 1}
                                                            setQuantity={
                                                              product.setQuantity || undefined
                                                            }
                                                          />

                                                          <Button
                                                            size="icon"
                                                            type="button"
                                                            variant="ghost"
                                                            className="ml-auto"
                                                            onClick={() =>
                                                              onChange(
                                                                accessories.map((a, index) =>
                                                                  index === i
                                                                    ? {
                                                                        products: a.products.filter(
                                                                          (_, pIdx) =>
                                                                            pIdx !== productIdx
                                                                        ),
                                                                      }
                                                                    : a
                                                                )
                                                              )
                                                            }
                                                          >
                                                            <Trash2 className="size-5 text-destructive sm:size-6" />
                                                          </Button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </FormControl>
                                                  <FormMessage />
                                                </FormItem>
                                              );
                                            }}
                                          />
                                        );
                                      })}
                                      {variants.length > 1 && (
                                        <Button
                                          variant="link"
                                          type="button"
                                          className="max-sm:text-sm"
                                          onClick={() => {
                                            onChange(
                                              accessories.map((a, index) =>
                                                index === i
                                                  ? {
                                                      products: [
                                                        ...a.products,
                                                        {
                                                          productId: variants[0]?.productId,
                                                          quantity:
                                                            (variants[0]?.defaultQuantity || 1) *
                                                            (state?.quantity || 1),
                                                        },
                                                      ],
                                                    }
                                                  : a
                                              )
                                            );
                                          }}
                                        >
                                          <PlusCircle className="mr-1 size-4" /> Nog een{' '}
                                          {stateAccessory?.title} toevoegen
                                        </Button>
                                      )}
                                    </div>
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          );
                        })}
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="mt-4 flex w-full flex-col items-center justify-center space-y-2">
                <Button className="w-full" disabled={loading}>
                  <span className="mr-1 sm:hidden">Artikel</span>
                  <span className="mr-1 max-sm:hidden">{title}</span>
                  met accessoires toevoegen
                </Button>
                <Button
                  disabled={loading}
                  variant="ghost"
                  className="w-full"
                  type="button"
                  onClick={() => {
                    if (state) addToCart([state]);
                  }}
                >
                  Nee bedankt, alleen
                  <span className="mx-1 sm:hidden">het artikel</span>
                  <span className="mx-1 max-sm:hidden">{title}</span>
                  toevoegen
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
};
